@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-profile-inforamation{
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.rt-profileimage{
    width: 180px;
    height: 180px;
    margin: 0 auto 16px;
    line-height: 180px;
}

.rt-titlebox{
    display: flex;
    text-align: center;
    margin-bottom: 24px;
    flex-direction: column;

    h2{
        color: $nero;
        margin: 0 0 6px;
        font-size: 1.5rem;
        @include norwester;
        text-transform: uppercase;
    }
    span{
        display: block;
        color: $zambezi;
        margin-bottom: 6px;
        font-size: 1.25rem;
        @include norwester;
        text-transform: uppercase;
    }
}
.rt-profileactions{
    .rt-black-btn{
        margin: 0 8px;
        min-width: 226px;
        line-height: 70px;
        font-size: 1.25rem;

        i{
            margin-right: 20px;
            vertical-align: top;
            line-height: inherit;
        }

        span{
            vertical-align: top;
        }
    }
    .rt-settingbtn{
        min-width: auto;
        i{
            margin: 0;
            font-size: 30px;
        }
    }
}
.rt-counterbox{
    width: 100%;
    display: flex;
    padding: 20px 0;
    margin-bottom: 30px;
    align-items: center;
    @include cardlayout;
    justify-content: space-around;

    .rt-count{
        text-align: center;

        h3{
            color: $nero;
            font-size: 2.5rem;
            @include norwester;
            margin-bottom: 6px;
            text-transform: uppercase;
        }

        span{
            display: block;
            color: $PinkSwan;
            font-size: 1.125rem;
        }
    }
    .rt-followingmodal-btn{
        cursor: pointer;
    }
}
.rt-trainingandtrophies{
    margin-bottom: 30px;
}
.rt-title{
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    h2{
        margin: 0;
        font-size: 1.5rem;
        @include norwester;
    }
}
.rt-trainingresulat{
    padding: 20px;
    min-height: 278px;
    @include cardlayout;
}
.rt-traininglist{
    width: 100%;
    list-style: none;
    margin-bottom: 25px;
    li{
        color: $nero;
        display: flex;
        padding: 5px 0;
        @include norwester;
        font-size: 1.25rem;
        align-items: center;
        list-style-type: none;
        text-transform: uppercase;
        justify-content: space-between;
        &:first-child{
            padding-top: 0;
        }
        span{
            display: inline-block;
        }
    }
}
.rt-resultbox{
    display: flex;
    justify-content: space-between;
}
.rt-resultcount{
    text-align: center;
    strong{
        color: $nero;
        display: block;
        font-size: 16px;
        @include norwester;
        margin-bottom: 10px;
    }
    span{
        display: block;
        font-size: 16px;
        color: $PinkSwan;
        @include norwester;
    }
}
.rt-trophies{
    padding: 10px;
    min-height: 278px;
    @include cardlayout;
}
.rt-trophilist{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li{
        padding: 10px;
        list-style-type: none;
    }
}
.rt-goalsarea{
    .rt-title{
        justify-content: space-between;

        .rt-border-btn{
            padding: 0 14px;
            line-height: 32px;
            @include bd-radius(8px);
            text-transform: uppercase;
            i{
                margin-right: 8px;
                vertical-align: top;
                line-height: inherit;
            }
            span{
                vertical-align: top;
                line-height: inherit;
            }
        }
    }
}
.rt-goalcard{
    @include cardlayout;
    margin-bottom: 10px;

    .rt-datebox{
        padding: 15px 20px;

        span{
            color: $nero;
            display: block;
            font-size: 16px;
            @include roboto-regular;
        }
    }
}
.rt-goalcontent{
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid $DarkGraylght;

    .rt-title{
        margin: 0;
        flex-direction: column;
        align-items: flex-start;

        h3{
            color: $nero;
            font-size: 1.125rem;
            margin-bottom: 15px;
        }
    }

    .rt-description{
        p{
            margin: 0;
            font-size: 14px;
            color: $DarkGray;
            text-transform: uppercase;
        }
    }

    .rt-deletbtn{
        background: transparent;
    }
}

/* =============================================
            Find Member Modal Styling
============================================= */
.rt-findmembermodal{
    .modal-dialog{
        max-width: 600px;
    }
}
.rt-findmembermodalbox{
    padding: 58px 24px 18px;
}
.rt-searchbox{
    display: flex;
    margin-bottom: 30px;
}
.rt-memberlist{
    height: 500px;
    list-style: none;
    overflow-y: scroll;
    padding-right: 5px;
    @include scrollbar;

    li{
        display: flex;
        padding: 12px 0;
        align-items: center;
        justify-content: space-between;

        .rt-btn{
            font-size: 16px;
            padding: 0 24px;
            line-height: 40px;
            @include bd-radius(8px);

        }
    }

    .rt-themecontent{
        h4{
            display: flex;
            font-size: 1.25rem;
            align-items: center;

            em{
                font-size: 14px;
                color: $DarkGray;
                margin-left: 10px;
                font-style: normal;
                @include norwester;
                display: inline-block;
                text-transform: uppercase;
            }
        }
        span{
            color: $nero;
            display: block;
            font-size: 16px;
            @include norwester;

        }
    }
}

/* =============================================
            Add Goal Modal Styling
============================================= */
.rt-addgoalmodal{
    .modal-dialog{
        max-width: 500px;
    }
}
.rt-addgoalmodalbox{
    padding: 58px 24px 30px;
}
.rt-addgoalrcontent{
    .rt-textarea{
        height: 130px;
        textarea.form-control{
            height: 80px;
        }

        label{
            transform: translateY(-50px);
        }
    }
}

/* =============================================
            Delete Modal Styling
============================================= */
.rt-deletemodal{
    .modal-dialog{
        max-width: 500px;
    }
}
.rt-deletemodalbox{
    padding: 58px 24px 30px;
}
.rt-deletecontent{
    .rt-modaltitle{
        text-align: center;
        h3{
            font-size: 1.625rem;
        }
    }
    .rt-description{
        padding: 30px 0;

        p{
            margin: 0;
            font-size: 16px;
            color: $suvagrey;
            @include roboto-regular;
        }
    }
}

/* =============================================
            Edit Profile Styling
============================================= */
.rt-editprofileform{
    .rt-profileimage{
        margin: 26px auto 28px;
    }
    .rt-black-btn{
        min-width: 100%;
        margin-top: 28px;
    }
}
/*========================================
            Responsive 
==========================================*/

@media (max-width: 991px) {
    .rt-trainingresulat-area{
        margin-bottom: 30px;
    }
}
@media (max-width: 767px) {
    .rt-profileactions{
        .rt-black-btn{
            line-height: 65px;
            min-width: auto;
            i{
                margin: 0;
                font-size: 22px;
            }
            span{
                display: none;
            }
        }
    }
}
@media (max-width: 575px) {
    .rt-memberlist{
        li{
            .rt-themecardbox{
                flex-direction: column;
                align-items: flex-start;
                .rt-roundimage{
                    margin: 0 0 15px;
                }
            }
        }
    } 
}
@media (max-width: 479px) {
    .rt-memberlist{
        li{
            flex-direction: column;
            // align-items: flex-start;
            .rt-themecardbox{
                align-items: center;
                text-align: center;
                .rt-themecontent {
                    margin-bottom: 10px;
                }
            } 
        }
    }
    .rt-thememodalbox .rt-btns .rt-btn{
        width: 100%;
    }
    .rt-thememodalbox .rt-btns .rt-border-btn{
        width: 100%;
        margin: 0 0 15px;
    }
    .rt-goalcontent .rt-title h3{
        font-size: 16px;
    }
}